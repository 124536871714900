<template>
  <div id="manage-banner-list" class="fx-main container pd-t-1-em">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.97"
      :z-index="2147480000"
    ></loading>
    <div ref="listRef">
      <div class="input-group fx-main mg-1-bot">
        <button class="clickable btn" v-on:click="showModal()">Criar Novo Banner</button>
      </div>
      <div class="main-grid">
        <div
          v-for="(banner, index) in banners"
          :key="banner.image_file_id"
          class="fx-main manage-banner-item default-border fx-a-i-cent mg-top-1 main-grid gd-tp-c gd-tp-r shadow pd-2-em"
        >
          <div class="manage-banner-img circle shadow v-h-center txt-align-center">
            <img class="circle" :src="getBannerImageUrl(banner)" :alt="banner.alternate_text" />
          </div>
          <div class="manage-banner-name txt-align-center">
            <span class="banner-name">{{ banner.name }}</span>
          </div>
          <div class="edit-banner ">
            <div class="manage-banner-menu-modal fx-center 1fx-wrap style-icons pd-15-px">
            <div
              class="label label-table label-danger clickable left-img"
              @click="leftBanner(index)"
              >
              <i class="fas fa-angle-left"></i>
            </div>
              <div v-on:click="showModal(banner.id)" class="default-border clickable mg-right-1-em">
                <i class="fas fa-pencil-alt"></i>
              </div>

              <div v-on:click="deleteBanner(banner.id)" class="default-border clickable">
                <i class="fas fa-trash"></i>
              </div>
              <div
                class="label label-table label-danger clickable right-img"
                @click="rightBanner(index)"
                >
                <i class="fas fa-angle-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal name="banner-manage-modal" :width="'85%'" :height="'80%'" :adaptive="true">
      <div class="modal-header fx-main fx-end">
        <i class="clickable fa fa-times" style="padding: 10px" aria-hidden="true" @click="hideModal"></i>
      </div>
      <div class="fx-main container wd-100">
        <div id="FormBanner" class="wd-100">
          <div class="input-group fx-main mg-bot-2em">
            <label class="mg-right-2-em" for="banner_form_name">Nome</label>
            <input
              class="default-border"
              type="text"
              id="banner_form_name"
              v-model="bannerModal.name"
            />
          </div>
          <div class="input-group fx-main mg-bot-2em">
            <label class="mg-right-2-em" for="banner_form_alternate_text">Texto Alternativo</label>
            <textarea
              class="default-border"
              type="text"
              id="banner_form_alternate_text"
              v-model="bannerModal.alternate_text"
            />
          </div>
          <div class="input-group fx-main mg-bot-2em">
            <label class="mg-right-2-em" for="banner_form_link">Link</label>
            <input
              class="default-border"
              type="text"
              id="banner_form_link"
              v-model="bannerModal.link"
            />
          </div>
          <div class="input-group fx-main mg-bot-2em fx-a-i-cent">
            <span class="mg-right-2-em">Visível</span>
            <div class="switch">
              <input
                type="checkbox"
                class="switch__input"
                id="banner_form_published"
                v-model="bannerModal.published"
                :true-value="true"
                :false-value="false"
              />
              <label for="banner_form_published" class="switch__label"></label>
            </div>
          </div>
          <div class="input-group fx-main mg-bot-2em">
            <label class="mg-right-2-em" for="name">Imagem</label>
            <input class="default-border" type="file" @change="processFile($event)" />
          </div>
          <div class="input-group fx-main mg-bot-2em">
            <button
              class="btn clickable"
              v-on:click="bannerModal.id ? updateBanner() : createBanner()"
            >{{ bannerModal.id ? 'Alterar' : 'Criar' }}</button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<style scoped>
  .style-icons{
    display: flex;
    justify-content: space-around;
  }
  
</style>

<script>
import Banners from "@/services/banners.js";
import util from "@/services/util";
import arrayMove from "array-move";
import Loading from "vue-loading-overlay";

function generate_banner() {
  return {
    name: "",
    alternate_text: "",
    published: true
  };
}

export default {
  name: "manage-banner-list",
  data: function() {
    return {
      banners: [],
      bannerModal: generate_banner(),
      binaryDataImg: [],
      searchParameters: {
        page: 0,
        perPage: 10
      },
      loadingMore: false,
      loadIndex: 0,
      over: false
    };
  },
  mounted() {
    this.listBanners();
    setTimeout(() => window.addEventListener("scroll", this.handleScroll), 500);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    listBanners: function(push = false) {
      if (push) {
        if (this.loadingMore || this.over) {
          return;
        }
        this.searchParameters.page++;
        this.loadingMore = true;
      } else {
        this.searchParameters.page = 0;
        this.loadingMore = false;
        this.over = false;
        this.loadIndex++;
      }
      Banners.list(this.searchParameters)
        .then(banners => {
          if (!push) {
            this.banners = banners;
          } else {
            if (banners.length === 0) {
              this.over = true;
            }
            this.banners.push(...banners);
            this.$forceUpdate();
            setTimeout(() => (this.loadingMore = false), 100);
          }
        })
        .finally(() => {
          if (!push) {
            this.loadIndex--;
          }
        });
    },
    handleScroll(event) {
      if (!this.$refs.listRef) {
        return;
      }
      let screenHeight = screen.height;
      let scrollTop = document.documentElement.scrollTop;
      let listStart = util.findPos(this.$refs.listRef, document.documentElement)
        .y;
      let listEnd = listStart + this.$refs.listRef.scrollHeight;
      let diff = listEnd - scrollTop;
      let bottomOfWindow = diff <= screenHeight * 1.2;
      if (bottomOfWindow) {
        this.listBanners(true);
      }
    },
    showModal: function(bannerId) {
      this.binaryDataImg = [];
      if (bannerId) {
        this.loadIndex++;
        Banners.get(bannerId)
          .then(banner => {
            if (banner.parent) {
              banner.parent = banner.parent.id;
            }
            this.bannerModal = banner;
            this.$modal.show("banner-manage-modal");
          })
          .finally(() => {
            this.loadIndex--;
          });
      } else {
        this.bannerModal = generate_banner();
        this.$modal.show("banner-manage-modal");
      }
    },
    hideModal: function() {
      this.$modal.hide("banner-manage-modal");
    },
    updateBanner: function() {
      this.loadIndex++;
      Banners.edit(this.bannerModal, (this.binaryDataImg || [])[0])
        .then(
          banner => {
            this.$notify({
              duration : 24000,
              type: "success",
              title: "Banner Atualizado",
              text: "Banner Atualizado com Sucesso!"
            });
            this.banners = this.banners.map(p =>
              p.id == this.bannerModal.id ? banner : p
            );
            this.$forceUpdate();
            this.hideModal();
          },
          error => {
            this.$notify({
              duration : 24000,
              type: "error",
              title: "Erro ao Editar Banner",
              text: util.stringifyAxiosError(error)
            });
          }
        )
        .finally(() => {
          this.loadIndex--;
        });
    },
    createBanner: function() {
      this.loadIndex++;
      Banners.create(this.bannerModal, (this.binaryDataImg || [])[0])
        .then(
          banner => {
            this.$notify({
              duration : 24000,
              type: "success",
              title: "Banner Criado",
              text: "Banner Criado com Sucesso!"
            });
            this.banners = [...this.banners, banner];
            this.$forceUpdate();
            this.hideModal();
          },
          error => {
            this.$notify({
              duration : 24000,
              type: "error",
              title: "Erro ao Criar Banner",
              text: util.stringifyAxiosError(error)
            });
          }
        )
        .finally(() => {
          this.loadIndex--;
        });
    },
    deleteBanner: function(id) {
      this.$swal({
        title: "Tem certeza que deseja APAGAR o Banner?",
        text: "Não é possível reverter essa ação!",
        icon: "warning",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: false,
            visible: true,
            className: "",
            closeModal: true
          },
          confirm: {
            text: "Sim, pode apagar!",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
            dangerMode: true
          }
        }
      }).then(result => {
        if (!result) {
          return;
        }
        this.loadIndex++;
        Banners.delete(id)
          .then(
            banner => {
              this.$notify({
                duration : 24000,
                type: "success",
                title: "Banner Excluído",
                text: "Banner Excluído com Sucesso!"
              });
              this.banners = this.banners.filter(p => p.id != id);
              this.$forceUpdate();
            },
            error => {
              this.$notify({
                duration : 24000,
                type: "error",
                title: "Erro ao Excluir Banner",
                text: util.stringifyAxiosError(error)
              });
            }
          )
          .finally(() => {
            this.loadIndex--;
          });
      });
    },
    processFile(event) {
      this.binaryDataImg = event.target.files;
    },
    getBannerImageUrl(banner) {
      return Banners.getImageUrl(banner);
    },
    leftBanner: function(index) {
      if (index == 0) {
        return;
      }
      this.banners = arrayMove(this.banners, index, index - 1);
      this.$forceUpdate();
      this.commitBannerMove();
    },
    rightBanner: function(index) {
      if (index == this.banners.length - 1) {
        return;
      }
      this.banners = arrayMove(this.banners, index, index + 1);
      this.$forceUpdate();
      this.commitBannerMove();
    },
    commitBannerMove: function() {
      Banners.reorder(this.banners).then(
        banner => {
          this.$notify({
            duration : 24000,
            type: "success",
            title: "Banners Reordenados",
            text: "Banners Reordenados com Sucesso!"
          });
        },
        error => {
          this.$notify({
            duration : 24000,
            type: "error",
            title: "Erro ao Reordenar Banners",
            text: util.stringifyAxiosError(error)
          });
        }
      );
    }
  },
  components: {
    Loading
  },
  computed: {
    isLoading: function() {
      return this.loadIndex > 0;
    }
  }
};
</script>